import s from './TextField.module.scss';

import { forwardRef } from 'react';

const TextField = forwardRef(
    (
        {
            mode = 'edit',
            type = 'text',
            size = 'md',
            labelName = '',
            placeholder = '',
            disabled,
            value = '',
            helpMessage = '',
            errorMessage = '',
            onChangeHandler = undefined,
            onKeyDownHandler = undefined,
            maxLength,
            styleProps = {},
            autoFocus = false,
        },
        ref
    ) => {
        return (
            <div className={s.container}>
                <input
                    ref={ref}
                    type={type}
                    id={labelName}
                    className={`${s.input} ${s[size]} ${s[mode]} ${errorMessage ? s.error : ''}`}
                    disabled={disabled}
                    placeholder={mode === 'edit' ? placeholder : ''}
                    value={value}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHandler}
                    maxLength={maxLength}
                    readOnly={mode === 'read'}
                    autoComplete='off'
                    style={{ ...styleProps }}
                    autoFocus={autoFocus}
                />
                {helpMessage}
                {errorMessage && (
                    <p className={s.error_message}>{errorMessage}</p>
                )}
            </div>
        );
    }
);

export default TextField;
