// CSS
import './SignUpAccountInfoPage.scss';

// Hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Librarys
import axiosUtil from 'src/utils/axiosUtil';
import { encryptObject } from 'src/utils/encryptUtil';
import { checkEmailFormat } from 'src/utils/checkFormat';
import { countries } from 'countries-list';

// Assets
import { ReactComponent as RefactorLogo } from 'src/assets/logo/OFRefactorLogo_color.svg';
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrowRightIcon.svg';

// Components
import TopLabelInput from 'src/components/atoms/TopLabelInput/TopLabelInput';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import Label from 'src/components/atoms/Label/Label';
import BaseSelect from 'src/components/atoms/BaseSelect/BaseSelect';
import Link from 'src/components/atoms/Link/Link';

const sortCountryArrary = () => {
    const array = Object.values(countries)
        .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        })
        .map((el, idx) => {
            return {
                name: el.name,
                selected: idx === 0 ? true : false,
            };
        });

    return array;
};

const SignUpAccountInfoPage = ({ setEmail }) => {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasUpperLowerCase, setHasUpperLowerCase] = useState(false);
    const [hasNumbers, setHasNumbers] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasSamePassword, setHasSamePassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [isInvalidateEmailFormat, setIsInvalidateEmailFormat] =
        useState(true);
    const [warningMessage, setWarningMessage] = useState('');

    const [countryArray] = useState(() => sortCountryArrary());
    const [selectedCountryCodes, setSelectedCountryCodes] = useState(() => [
        {
            name: Object.values(countries)
                .find((el) => el.name === countryArray[0].name)
                .phone[0].toString(),
            selected: true,
        },
    ]);

    const [userInfoValue, setUserInfoValue] = useState(() => ({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirmPassword: '',
        company_name: '',
        country: countryArray[0].name,
        department: '',
        job_title: '',
        country_code: selectedCountryCodes[0].name,
        company_number: '',
    }));

    const getCountryCodes = () => {
        const matchingCodes = Object.values(countries).filter(
            (el) => el.name === userInfoValue.country
        )[0].phone;

        const convertedArray = matchingCodes.map((el, idx) => ({
            name: el.toString(),
            selected: idx === 0,
        }));

        return convertedArray;
    };

    useEffect(() => {
        const codes = getCountryCodes();
        setSelectedCountryCodes(() => codes);
        setUserInfoValue((prev) => ({
            ...prev,
            country_code: codes[0].name,
        }));
    }, [userInfoValue.country]);

    const onEmailFieldChange = (e) => {
        onChangeHandler('email', e.target.value);
        setIsInvalidateEmailFormat(!checkEmailValidation(e.target.value));
        if (e.target.value !== '' && !checkEmailValidation(e.target.value))
            setWarningMessage(
                ' This is not a valid email. Please check your email address.'
            );
        else setWarningMessage('');
    };

    const checkEmailValidation = (value) => {
        if (value === '') return false;

        return checkEmailFormat(value);
    };

    const passwordConditions = [
        {
            text: '- Must contain an uppercase and a lowercase letter (A,z)',
            condition: hasUpperLowerCase,
        },
        {
            text: '- Must be at least 12 characters long.',
            condition: hasMinimumLength,
        },
        {
            text: '- Must contain a number',
            condition: hasNumbers,
        },
        {
            text: '- Must contain a special character (!, %, @, #, etc.)',
            condition: hasSpecialCharacters,
        },
    ];

    const checkPasswordRule = (password) => {
        const minumumLength = 12;
        setHasMinimumLength(minumumLength <= password.length);
        setHasUpperLowerCase(/[A-Z]/.test(password) && /[a-z]/.test(password));
        setHasNumbers(/\d/.test(password));
        setHasSpecialCharacters(
            /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=|\\'\\"]/g.test(password)
        );
    };

    const onNewPasswordFieldChange = (e) => {
        setNewPassword(e.target.value);
        checkPasswordRule(e.target.value);
    };

    const onConfirmNewPasswordFieldChange = (e) => {
        setConfirmNewPassword(e.target.value);
        setHasSamePassword(newPassword === e.target.value);
    };

    const onChangeHandler = (target, value) => {
        const _userInfoValue = {
            ...userInfoValue,
        };
        _userInfoValue[target] = value;
        setUserInfoValue(_userInfoValue);
    };

    const handleClickCreateAccount = async () => {
        const requestData = await encryptObject({
            user_name: userInfoValue.email,
            first_name: userInfoValue.first_name,
            last_name: userInfoValue.last_name,
            password: newPassword,
            company_name: userInfoValue.company_name,
            department: userInfoValue.department,
            job_title: userInfoValue.job_title,
            country: userInfoValue.country,
            company_number: userInfoValue.company_number
                ? userInfoValue.country_code + userInfoValue.company_number
                : '',
            support_plan: 'basic',
        });

        axiosUtil({
            url: `/auth/signup`,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            data: requestData,
        })
            .then((res) => {
                setEmail(userInfoValue.email);
            })
            .catch((err) => {
                const message = err.response.data.message;
                alert(message ?? 'Sign up Error.');
            });
    };

    return (
        <>
            <div className='SignUpAccountInfoPage'>
                <div className='header'>
                    <RefactorLogo />
                    <div className='button_wrapper'>
                        <h4>Already have OpenFrame Refactor account?</h4>
                        <button onClick={() => navigate('/signin')}>
                            <span>Sign In</span>
                            <ArrowRightIcon className='icon' />
                        </button>
                    </div>
                </div>

                <div className='content_wrapper'>
                    <div className='title_wrapper'>
                        <h1>Your Workload Freedom Start Here.</h1>
                        {/* <h3>Experience how OpenFrame Refactor modernizied<br />
                        AWS card demo for 15-days.</h3> */}
                    </div>

                    <div className='card_wrapper'>
                        <div className='card_top' />
                        <div className='card_content'>
                            <h2>Create your free account</h2>
                            {/* Company E-mail */}
                            <div className='input_wrapper'>
                                <TopLabelInput
                                    isRequired
                                    label={'Company E-mail'}
                                    targetId={'email'}
                                    placeholder={'name@company.com'}
                                    onChangeHandler={(e) => {
                                        onEmailFieldChange(e);
                                    }}
                                    value={userInfoValue.email}
                                    inputStyle={textInputStyle}
                                />

                                <div className='text_container'>
                                    <span>
                                        Please use your company email address.
                                    </span>
                                    <div className='warning_text'>
                                        {warningMessage}
                                    </div>
                                </div>
                            </div>

                            <div className='field_wrapper'>
                                {/* First Name */}
                                <TopLabelInput
                                    isRequired
                                    label={'First Name'}
                                    targetId={'first_name'}
                                    placeholder={'First Name'}
                                    onChangeHandler={(e) => {
                                        onChangeHandler(
                                            'first_name',
                                            e.target.value
                                        );
                                    }}
                                    value={userInfoValue.first_name}
                                    inputStyle={shortTextInputStyle}
                                />

                                {/* Last Name */}
                                <TopLabelInput
                                    isRequired
                                    label={'Last Name'}
                                    targetId={'last_name'}
                                    placeholder={'Last Name'}
                                    onChangeHandler={(e) => {
                                        onChangeHandler(
                                            'last_name',
                                            e.target.value
                                        );
                                    }}
                                    value={userInfoValue.last_name}
                                    inputStyle={shortTextInputStyle}
                                />
                            </div>

                            <div className='field_container'>
                                <div className='field_wrapper'>
                                    <TopLabelInput
                                        isRequired
                                        label={'New password'}
                                        targetId={'password'}
                                        inputType={'password'}
                                        placeholder={'Password'}
                                        onChangeHandler={
                                            onNewPasswordFieldChange
                                        }
                                        value={newPassword}
                                        inputStyle={shortTextInputStyle}
                                    />

                                    <TopLabelInput
                                        isRequired
                                        label={'Confirm new password'}
                                        targetId={'confirm new password'}
                                        inputType={'password'}
                                        placeholder={'Confirm Password'}
                                        onChangeHandler={
                                            onConfirmNewPasswordFieldChange
                                        }
                                        value={confirmNewPassword}
                                        inputStyle={shortTextInputStyle}
                                    />
                                </div>

                                <div className='text_container'>
                                    {passwordConditions.map((el) => (
                                        <span
                                            className={el.condition && 'green'}
                                        >
                                            {el.text}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className='field_wrapper horizontal'>
                                <Label name={'Country'} isRequired />

                                <BaseSelect
                                    data={countryArray}
                                    value={userInfoValue.country}
                                    onChange={(name) => {
                                        onChangeHandler('country', name);
                                    }}
                                    inputStyle={selectInputStyle}
                                    textStyle={selectTextStyle}
                                />
                            </div>

                            <div className='field_wrapper'>
                                <TopLabelInput
                                    isRequired
                                    label={'Company Name'}
                                    targetId={'company_name'}
                                    placeholder={'Company Name'}
                                    onChangeHandler={(e) => {
                                        onChangeHandler(
                                            'company_name',
                                            e.target.value
                                        );
                                    }}
                                    value={userInfoValue.company_name}
                                    inputStyle={shortTextInputStyle}
                                />
                                <TopLabelInput
                                    label={'Department'}
                                    targetId={'department'}
                                    placeholder={'Department'}
                                    onChangeHandler={(e) => {
                                        onChangeHandler(
                                            'department',
                                            e.target.value
                                        );
                                    }}
                                    value={userInfoValue.department}
                                    inputStyle={shortTextInputStyle}
                                />
                            </div>

                            <div className='field_wrapper'>
                                {/* Job Title */}
                                <TopLabelInput
                                    isRequired
                                    label={'Job Title'}
                                    targetId={'job_title'}
                                    placeholder={'Job Title'}
                                    onChangeHandler={(e) => {
                                        onChangeHandler(
                                            'job_title',
                                            e.target.value
                                        );
                                    }}
                                    value={userInfoValue.job_title}
                                    inputStyle={shortTextInputStyle}
                                />
                                {/* Company Number */}
                                <div className='field_wrapper horizontal'>
                                    <Label name={'Company Number'} />

                                    <div className='field_wrapper'>
                                        <BaseSelect
                                            data={selectedCountryCodes}
                                            value={userInfoValue.country_code}
                                            onChange={(name) => {
                                                onChangeHandler(
                                                    'country_code',
                                                    name
                                                );
                                            }}
                                            inputStyle={shortSelectInputStyle}
                                            textStyle={selectTextStyle}
                                        />

                                        <input
                                            type={'text'}
                                            placeholder={'1234567890'}
                                            onChange={(e) => {
                                                onChangeHandler(
                                                    'company_number',
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    )
                                                );
                                            }}
                                            value={userInfoValue.company_number}
                                            className='input_field'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='checkbox_container'>
                                <input
                                    className='checkbox'
                                    id='policy_checkbox'
                                    type='checkbox'
                                    onClick={(e) => {
                                        setIsChecked(e.target.checked);
                                    }}
                                />
                                <label
                                    for='policy_checkbox'
                                    className='check_text'
                                >
                                    By continuing, you agree to the TmaxSoft’s{' '}
                                    <Link
                                        text='Terms of Service.'
                                        type='exposed'
                                        onClick={() =>
                                            (window.location.href =
                                                'http://portal.tmaxsoft-openframe.com/termsandconditions')
                                        }
                                        styleProps={{ display: 'inline' }}
                                    />
                                    {' The '}
                                    <Link
                                        text='Privacy Notice'
                                        type='exposed'
                                        onClick={() =>
                                            (window.location.href =
                                                'http://portal.tmaxsoft-openframe.com/privacypolicy')
                                        }
                                        styleProps={{ display: 'inline' }}
                                    />
                                    {' describes how your data is handled.'}
                                </label>
                            </div>

                            <BasicButton
                                content={'Create Account'}
                                onClick={handleClickCreateAccount}
                                styleProps={{
                                    width: '480px',
                                    height: '40px',
                                }}
                                disabled={
                                    !(
                                        !isInvalidateEmailFormat &&
                                        isChecked &&
                                        hasMinimumLength &&
                                        hasUpperLowerCase &&
                                        hasNumbers &&
                                        hasSpecialCharacters &&
                                        hasSamePassword &&
                                        userInfoValue.company_name &&
                                        userInfoValue.job_title &&
                                        userInfoValue.first_name &&
                                        userInfoValue.last_name &&
                                        userInfoValue.email
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpAccountInfoPage;

const textInputStyle = {
    width: '480px',
};
const shortTextInputStyle = {
    width: '236px',
};

const selectInputStyle = {
    width: '480px',
    height: '40px',
    padding: '9px 12px',
};

const shortSelectInputStyle = {
    ...selectInputStyle,
    width: '80px',
};

const selectTextStyle = {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '-0.002em',
};
