import s from './FilterChip.module.scss';

function FilterChip({ index, icon, text, count, selected, onClick }) {
    const selectedStyle = selected ? 'selected' : '';

    return (
        <button
            className={`${s.filterChipContainer} ${s[selectedStyle]}`}
            onClick={() => onClick(index)}
        >
            {icon && <div className={s.icon}>{icon}</div>}
            <p className={s.chipText}>{text}</p>
            {count && <p>{count}</p>}
        </button>
    );
}

export default FilterChip;
