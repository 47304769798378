import styles from './Breadcrumb.module.scss';

import { useNavigate } from 'react-router-dom';

function Item({ itemName, property = 'default', location }) {
    const navigate = useNavigate();
    const propertyStyle = styles[property];

    return (
        <button
            className={`${styles.itemContainer} ${propertyStyle}`}
            onClick={() => navigate(location)}
        >
            <p className={styles.breadcrumbTypo}>{itemName}</p>
        </button>
    );
}

export default function Breadcrumb({ items }) {
    return (
        <div className={`${styles.breadcrumbContainer}`}>
            {items.map(({ ...props }) => {
                return <Item key={props.itemName} {...props} />;
            })}
        </div>
    );
}
