import s from './AccordianButton.module.scss';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrowDownIcon.svg';

function AccordianButton({ onClick, open }) {
    const openStyle = open ? 'on' : 'off';

    return (
        <button className={`${s.button} ${s[openStyle]}`} onClick={onClick}>
            <ArrowDownIcon className={s.icon} />
        </button>
    );
}

export default AccordianButton;
