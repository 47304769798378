import axiosUtil from 'src/utils/axiosUtil';
import { clearLocalStorage } from 'src/utils/clearLocalStorage';

export const refreshToken = async () => {
    await axiosUtil({
        url: `/auth/refresh`,
        method: 'POST',
    })
        .then((res) => {
            localStorage.setItem('OpenFrameAccessToken', res.data.access_token);
            return true;
        })
        .catch((err) => {
            const { response } = err;

            if (response.status === 400 || response.status === 404) {
                if (localStorage.getItem('OpenFrameAccessToken')) {
                    alert('Your login has expired.');
                    clearLocalStorage();
                    window.location.replace('/signin');
                }
            }
        });
};
