import axiosUtil from 'src/utils/axiosUtil';

const encryptText = async (plainText) => {
    try {
        const PUBLIC_KEY = await getPublicKey();
        const cryptoKey = await importRsaKey(PUBLIC_KEY);
        return encrypt(cryptoKey, plainText);
    } catch (err) {
        console.log('encrypt err', err);
    }
};

const encryptObject = async (plainObject) => {
    try {
        const PUBLIC_KEY = await getPublicKey();
        const cryptoKey = await importRsaKey(PUBLIC_KEY);

        const encryptObj = {};
        for (const [key, plainText] of Object.entries(plainObject)) {
            encryptObj[key] = await encrypt(cryptoKey, plainText);
        }
        return encryptObj;
    } catch (err) {
        console.error('encrypt err', err);
    }
};

const getPublicKey = () => {
    return new Promise((resolve) => {
        axiosUtil
            .get(`/auth/rsa/publickey`)
            .then((res) => {
                resolve(res.data.key);
            })
            .catch((err) => {
                console.log('publicKey err', err);
            });
    });
};

function importRsaKey(pem) {
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pem);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    return crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        true,
        ['encrypt']
    );
}

const encrypt = async (cryptoKey, plainText) => {
    const encodedText = new TextEncoder().encode(plainText);
    const encrypted = await crypto.subtle.encrypt(
        { name: 'RSA-OAEP' },
        cryptoKey,
        encodedText
    );

    const encryptedBase64 = window.btoa(ab2str(encrypted));
    return encryptedBase64;
};

function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export { encryptText, encryptObject };
