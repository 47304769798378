import s from './DeploymentStatusChip.module.scss';
import { convertIcon, convertStatus } from 'src/utils/convert';

const DeploymentStatusChip = ({ type, deleted }) => {
    const status = deleted ? 'DELETE_IN_PROGRESS' : type;

    return (
        <div className={`${s.DeploymentStatusChip} ${s[status]}`}>
            <div className={s.icon}>{convertIcon(status)}</div>
            <span className={s.title}>{convertStatus(status)}</span>
        </div>
    );
};

export default DeploymentStatusChip;
