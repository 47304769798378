import style from './DeploymentDetail.module.scss';

import axiosUtil from 'src/utils/axiosUtil';
import { convertStatus, convertTimestamp } from 'src/utils/convert';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import { ReactComponent as CancelIcon } from 'src/assets/icons/cancel.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/deleted-icon.svg';

import DeploymentProgress from './DeploymentProgress/DeploymentProgress';
import FreeNotification from './FreeNotification/FreeNotification';
import TextField from 'src/components/atoms/TextField/TextField';
import Label from 'src/components/atoms/Label/Label';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import DeploymentStatusChip from 'src/components/molecules/DeploymentStatusChip';
import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';
import CommitButton from 'src/components/atoms/CommitButton/CommitButton';
import Link from 'src/components/atoms/Link/Link';

const OF_ANALYZER = 'OF Analyzer';
const DATASET_MANAGER = 'OF Dataset Manager';
const OF_MIGRATOR = 'OF Migrator';

const DeploymentDetail = ({ deployment, setLoading }) => {
    const navigate = useNavigate();

    const [deploymentInfo, setDeploymentInfo] = useState('');
    const [isDeleteDeploymentModalOpen, setIsDeleteDeploymentModalOpen] =
        useState(false);

    const getDeploymentInfo = async () => {
        await axiosUtil({
            url: `/deployments?stack_name=${deployment}`,
            method: 'GET',
        })
            .then((data) => {
                setDeploymentInfo(data.data);
                return data.data;
            })
            .catch((e) => {
                console.log(e);
                return null;
            });
    };

    useEffect(() => {
        if (deployment) {
            getDeploymentInfo();
        }
    }, [deployment]);

    if (!deploymentInfo || deploymentInfo?.tenant_name !== deployment)
        return <></>;

    const {
        cloud_provider,
        deployment_name,
        tenant_name,
        timestamp,
        tier,
        region,
        stack_status,
        option,
        deleted = false,
        requests: [{ free: requestsFree } = {}] = [{ free: undefined }],
    } = deploymentInfo;

    const createdDate = convertTimestamp(timestamp);

    const colDefs = [
        {
            field: 'Service Name',
            flex: 1,
            cellStyle: () => ({ display: 'flex' }),
            cellRenderer: (param) => {
                const region = deploymentInfo.region;
                const application =
                    param.value === DATASET_MANAGER ? 'dm' : 'app';
                let baseDomain = '';

                // dev
                if (process.env.REACT_APP_PROFILE === 'dev') {
                    baseDomain = `dev.${application}.${region}.${process.env.REACT_APP_CLIENT_URL}`;
                } else { // staging & production
                    baseDomain = `${application}.${region}.${process.env.REACT_APP_CLIENT_URL}`;
                }

                localStorage.setItem(
                    'DeploymentID',
                    deploymentInfo.deployment_name
                );

                const redirectionURL = `https://${baseDomain}`;

                const isValidStatus =
                    !deleted && convertStatus(stack_status) === 'Active';

                return isValidStatus ? (
                    <Link
                        type='exposed'
                        text={param.value}
                        onClick={() => window.open(redirectionURL)}
                    />
                ) : (
                    <span style={{ color: '#1C293E' }}>{param.value}</span>
                );
            },
        },
        {
            field: 'Status',
            cellStyle: () => ({ display: 'flex', alignItems: 'center' }),
            cellRenderer: (param) => (
                <DeploymentStatusChip type={stack_status} deleted={deleted} />
            ),
        },
        { field: 'Version' },
        { field: 'Start Date' }, // started, completed
    ];

    let rowData = [
        {
            'Service Name': OF_ANALYZER,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            'Start Date': createdDate,
            'End Date': createdDate,
            Workload: 'Falcon',
            Spec: '2vCPU, 8GB RAM',
        },
        {
            'Service Name': DATASET_MANAGER,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            'Start Date': createdDate,
            'End Date': createdDate,
            Workload: 'Falcon',
            Spec: 'Storage 500GB',
        },
        {
            'Service Name': `${OF_ANALYZER} / ${OF_MIGRATOR}`,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            'Start Date': createdDate,
            'End Date': createdDate,
            Workload: 'Falcon',
            Spec: '2vCPU, 12GB RAM',
        },
    ];

    if (option === '0' || option === OF_ANALYZER) rowData = [rowData[0]];
    else if (option === '1' || option === 'OF Data Migration')
        rowData = [rowData[0], rowData[1]];
    else if (option === '2' || option === 'OF Transformation')
        rowData = [rowData[2], rowData[1]];

    const handleDeleteDeployment = async () => {
        setLoading(true);
        await axiosUtil
            .delete(`/deployments`, {
                data: {
                    cloudProvider: cloud_provider,
                    tenant_name: tenant_name,
                    region: region,
                    bucket_name: `tmaxsoft-miner-ui-${tenant_name}`,
                },
            })
            .then((data) => {
                console.log(`${tenant_name} is deleted`);
            })
            .catch(() => {
                return null;
            })
            .finally(() => {
                setIsDeleteDeploymentModalOpen(false);
                setLoading(false);
            });
    };

    const DeleteDeploymentModalContent = (
        <div className={style.ModalContent}>
            <h3>Are you sure?</h3>
            <p>
                You will lose all the data to your deployment once your deletion
                request has been submitted.
            </p>
            <div className={style.deployment_name}>{deployment_name}</div>
        </div>
    );

    return (
        <div className={style.DeploymentDetail}>
            <div className={style.header}>
                <h2>{`Detail of Deployment Name [${deployment_name}]`}</h2>
                {!deleted && stack_status === 'CREATE_IN_PROGRESS' ? (
                    <BasicButton
                        level='secondary'
                        type='outline'
                        startIcon={<CancelIcon />}
                        content='Cancel Requestion'
                        onClick={() => setIsDeleteDeploymentModalOpen(true)}
                    />
                ) : (
                    !deleted && (
                        <div style={{ display: 'flex', gap: '8px' }}>
                            {requestsFree && (
                                <FreeNotification
                                    createdTimestamp={timestamp}
                                />
                            )}
                            <BasicButton
                                level='secondary'
                                type='outline'
                                startIcon={<DeleteIcon />}
                                content='Delete Deployment'
                                onClick={() =>
                                    setIsDeleteDeploymentModalOpen(true)
                                }
                            />
                        </div>
                    )
                )}
            </div>
            {isDeleteDeploymentModalOpen && (
                <SimpleModal
                    title={
                        stack_status === 'CREATE_IN_PROGRESS'
                            ? 'Cancel Requestion'
                            : 'Delete Deployment'
                    }
                    children={DeleteDeploymentModalContent}
                    onClickClose={() => setIsDeleteDeploymentModalOpen(false)}
                    commitButton={
                        <CommitButton
                            content='Delete'
                            onClick={handleDeleteDeployment}
                            styleProps={{
                                background: 'rgba(239, 68, 51, 1)',
                            }}
                        />
                    }
                />
            )}

            <div className={style.content}>
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Deployment created date'} />
                    </div>
                    <TextField
                        mode='read'
                        labelName={'Deployment created date'}
                        value={createdDate}
                        styleProps={{ width: '320px' }}
                    />
                </div>

                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Status'} />
                    </div>
                    <div className={style.status_container}>
                        <DeploymentStatusChip
                            type={stack_status}
                            deleted={deleted}
                        />
                        {convertStatus(stack_status) === 'Error' && (
                            <Link
                                type='hiddenArrow'
                                text={'관리자에게 문의'}
                                onClick={() => navigate('/support')}
                                styleProps={{ color: '#7A828E' }}
                            />
                        )}
                    </div>
                </div>
                <hr />

                {!deleted && stack_status === 'CREATE_IN_PROGRESS' ? (
                    <DeploymentProgress />
                ) : (
                    <>
                        {/** 서비스 모델 */}
                        <div className={style.horizontal_wrapper}>
                            <div className={style.label}>
                                <Label name={'Service Model'} />
                            </div>
                            <TextField
                                mode='read'
                                labelName={'Service Model'}
                                value={`Service Model (${option})`}
                                styleProps={{ width: '320px' }}
                            />
                        </div>
                        <div className={style.grid_container}>
                            <div
                                className='ag-theme-dui'
                                style={{ height: '120px' }}
                            >
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                />
                            </div>
                        </div>

                        {/** 티어 정보 */}
                        <hr />
                        <div className={style.horizontal_wrapper}>
                            <div className={style.label}>
                                <Label name={'Tier'} />
                            </div>
                            <TextField
                                mode='read'
                                labelName={'Tier'}
                                value={tier}
                                styleProps={{ width: '320px' }}
                            />
                        </div>
                        <div>
                            <div className={style.detail_desc}>
                                {rowData.map((v) => {
                                    return (
                                        <div
                                            key={v['Service Name']}
                                            className={style.horizontal_wrapper}
                                        >
                                            <div className={style.label} />
                                            <div
                                                className={
                                                    style.horizontal_wrapper_gray
                                                }
                                            >
                                                <div className={style.label}>
                                                    <Label
                                                        name={v['Service Name']}
                                                    />
                                                </div>
                                                <TextField
                                                    mode='read'
                                                    labelName={
                                                        'Deployment created date'
                                                    }
                                                    value={v.Spec}
                                                    styleProps={{
                                                        width: '320px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <hr />
                        <div className={style.horizontal_wrapper}>
                            <div className={style.label}>
                                <Label name={'Cloud Region'} />
                            </div>
                            <TextField
                                mode='read'
                                labelName={'Cloud Region'}
                                value={region}
                                styleProps={{ width: '320px' }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeploymentDetail;
