import { Navigate, Route, Routes } from 'react-router-dom';

// layout
import SecondLayout from '../layout/SecondLayout/SecondLayout';
import PreAuthenticationLayout from 'src/layout/PreAuthenticationLayout';

// pages
import SignIn from '../pages/SignIn/SignIn';
import SignUp from '../pages/SignUp/SignUp';

import Overview from '../pages/Overview/Overview';
import CreateDeployment from 'src/pages/Deployment/CreateDeployment/CreateDeployment';
import Account from 'src/pages/Account/Information/Account';
import Billing from 'src/pages/Account/Billing/Billing';
import Support from '../pages/Support/Support';
import Event from 'src/pages/Event/Event';

import RouterGuard from './RouterGuard';
import ForgotPassword from 'src/pages/ForgotPassword/ForgotPassword';
import Admin from 'src/pages/Admin/Admin';
import Deployment from 'src/pages/Deployment';

// utils
import useMoveToTop from '../hooks/useMoveToTop';
import RouteChangeTracker from 'src/utils/RouteChangeTracker';

export default function CommonRouter() {
    useMoveToTop();

    return (
        <>
            <RouteChangeTracker />
            <Routes>
                {/* OpenFrame LogIn Page */}
                <Route element={<PreAuthenticationLayout />}>
                    <Route path='/' exact element={<SignIn />} />
                    <Route path='/signin' element={<SignIn />} />

                    <Route
                        path='/forgot_password'
                        element={<ForgotPassword />}
                    />
                    <Route
                        path='/forgot_password/expired'
                        element={<ForgotPassword expired />}
                    />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/signup/:email' element={<SignUp />} />
                </Route>

                {/* OpenFrame Main Page */}
                <Route element={<RouterGuard />}>
                    <Route element={<SecondLayout />}>
                        {/* <Route path='/overview' exact element={<Overview />} /> */}
                        <Route
                            path='/deployment/create'
                            element={<CreateDeployment />}
                        />
                        <Route
                            path='/deployment/free'
                            element={<CreateDeployment />}
                        />
                        <Route
                            path='/deployment'
                            exact
                            element={<Deployment />}
                        />

                        <Route
                            path='/account'
                            exact
                            element={
                                <Navigate replace to='/account/information' />
                            }
                        />
                        <Route
                            path='/account/information'
                            exact
                            element={<Account />}
                        />
                        <Route
                            path='/account/billing'
                            exact
                            element={<Billing />}
                        />

                        <Route path='/support' exact element={<Support />} />
                        <Route path='/event' exact element={<Event />} />
                        <Route path='/admin' exact element={<Admin />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}
