// CSS
import "./Profile.css";

const isKorean = (name) => {
  const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  return korean.test(name);
};

const profileName = (name) => {
  return isKorean(name) ? name.toUpperCase()[1] : name.toUpperCase()[0];
};

const Profile = ({ name, size = "small", variation = 1 }) => {
  return (
    <>
      <div className="profileWrapper">
        <svg className={`profileBadge ${size} colorVariation_${variation}`}>
          <circle />
          <text>{profileName(name)}</text>
        </svg>
      </div>
    </>
  );
};

export default Profile;
