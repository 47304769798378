import s from './DeploymentProgress.module.scss';

import softHiImage from 'src/assets/illust/softHi.gif';
import bubbleImage from 'src/assets/illust/bubble.svg';

function DeploymentProgress() {
    return (
        <div className={s.DeploymentProgress}>
            <div className={s.image_container}>
                <img
                    src={bubbleImage}
                    alt='bubble'
                    style={{ position: 'absolute', top: '50px', left: '70px' }}
                />
                <img src={softHiImage} alt='say_hi' width={300} height={300} />
            </div>
            <div className={s.description}>
                <span>Deployment is being in progress.</span>
                <span>Please wait for a moment. It takes about 15 minutes</span>
            </div>
        </div>
    );
}

export default DeploymentProgress;
