import style from './RequestDetail.module.scss';

import axiosUtil from 'src/utils/axiosUtil';

import { useState } from 'react';

import { ReactComponent as CancelIcon } from 'src/assets/icons/cancel.svg';

import TextField from 'src/components/atoms/TextField/TextField';
import Label from 'src/components/atoms/Label/Label';
import DeploymentStatusChip from 'src/components/molecules/DeploymentStatusChip';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';
import CommitButton from 'src/components/atoms/CommitButton/CommitButton';
import { convertTimestamp } from 'src/utils/convert';

const OF_ANALYZER = 'OF Analyzer';
const DATASET_MANAGER = 'OF Dataset Manager';
const OF_MIGRATOR = 'OF Migrator';

const RequestDetail = ({ requestInfo, setLoading }) => {
    const {
        user_id,
        deployment_name,
        tier,
        region,
        option,
        requested_timestamp,
    } = requestInfo;
    const [isCancelRequestionModalOpen, setIsCancelRequestionModalOpen] =
        useState(false);

    let rowData = [
        {
            'Service Name': OF_ANALYZER,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            Workload: 'Falcon',
            Spec: '2vCPU, 8GB RAM',
        },
        {
            'Service Name': DATASET_MANAGER,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            Workload: 'Falcon',
            Spec: 'Storage 500GB',
        },
        {
            'Service Name': `${OF_ANALYZER} / ${OF_MIGRATOR}`,
            Status: 'Active',
            Progress: 'Falcon',
            Version: 'v5.2.0',
            Workload: 'Falcon',
            Spec: '2vCPU, 12GB RAM',
        },
    ];

    if (option === '0' || option === OF_ANALYZER) rowData = [rowData[0]];
    else if (option === '1' || option === 'OF Data Migration')
        rowData = [rowData[0], rowData[1]];
    else if (option === '2' || option === 'OF Transformation')
        rowData = [rowData[2], rowData[1]];

    const handleDeleteRequest = async () => {
        setLoading(true);
        await axiosUtil
            .delete(`/admin/requests/item`, {
                data: {
                    user_id: user_id,
                    requested_timestamp: requested_timestamp.toString(),
                },
            })
            .then((data) => {
                console.log(`${deployment_name} is deleted`);
            })
            .catch(() => {
                return null;
            })
            .finally(() => {
                setIsCancelRequestionModalOpen(false);
                setLoading(false);
            });
    };

    const CancelRequestionModalContent = (
        <div className={style.ModalContent}>
            <h3>Are you sure?</h3>
            <p>
                You will lose all the data to your deployment once your deletion
                request has been submitted.
            </p>
            <div className={style.deployment_name}>{deployment_name}</div>
        </div>
    );

    return (
        <div className={style.RequestDetail}>
            <div className={style.header}>
                <h2>{`Detail of Deployment Name [${deployment_name}]`}</h2>
                <BasicButton
                    level='secondary'
                    type='outline'
                    startIcon={<CancelIcon />}
                    content='Cancel Requestion'
                    onClick={() => setIsCancelRequestionModalOpen(true)}
                />
            </div>
            {isCancelRequestionModalOpen && (
                <SimpleModal
                    title={'Cancel Requestion'}
                    children={CancelRequestionModalContent}
                    onClickClose={() => setIsCancelRequestionModalOpen(false)}
                    commitButton={
                        <CommitButton
                            content='Delete'
                            onClick={handleDeleteRequest}
                            styleProps={{
                                background: 'rgba(239, 68, 51, 1)',
                            }}
                        />
                    }
                />
            )}

            <div className={style.content}>
                {/** Deployment created date */}
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Deployment created date'} />
                    </div>
                    <TextField
                        mode='read'
                        labelName={'Deployment created date'}
                        value={'-'}
                        styleProps={{ width: '320px' }}
                    />
                </div>

                {/** Status */}
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Status'} />
                    </div>
                    <div className={style.status_container}>
                        <div className={style.status_content}>
                            <DeploymentStatusChip type={'REQUESTED'} />
                            <span className={style.requested_time}>
                                ({convertTimestamp(requested_timestamp)})
                            </span>
                        </div>
                        <span className={style.help}>
                            A Deployment creation has been requested and is
                            awaiting approval. Please contact administrator.
                        </span>
                    </div>
                </div>

                {/** 서비스 모델 */}
                <hr />
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Service Model'} />
                    </div>
                    <TextField
                        mode='read'
                        labelName={'Service Model'}
                        value={`Service Model (${option})`}
                        styleProps={{ width: '320px' }}
                    />
                </div>

                {/** 티어 정보 */}
                <hr />
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Tier'} />
                    </div>
                    <TextField
                        mode='read'
                        labelName={'Tier'}
                        value={tier}
                        styleProps={{ width: '320px' }}
                    />
                </div>
                <div>
                    <div className={style.detail_desc}>
                        {rowData.map((v) => {
                            return (
                                <div
                                    key={v['Service Name']}
                                    className={style.horizontal_wrapper}
                                >
                                    <div className={style.label} />
                                    <div
                                        className={
                                            style.horizontal_wrapper_gray
                                        }
                                    >
                                        <div className={style.label}>
                                            <Label name={v['Service Name']} />
                                        </div>
                                        <TextField
                                            mode='read'
                                            labelName={
                                                'Deployment created date'
                                            }
                                            value={v.Spec}
                                            styleProps={{ width: '320px' }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <hr />
                <div className={style.horizontal_wrapper}>
                    <div className={style.label}>
                        <Label name={'Cloud Region'} />
                    </div>
                    <TextField
                        mode='read'
                        labelName={'Cloud Region'}
                        value={region}
                        styleProps={{ width: '320px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RequestDetail;
