// CSS
import styles from "./SecondLayout.module.scss";
// Components
import LNBPanel from "../../components/organisms/LNBPanel/LNBPanel";
import Header from "../../components/organisms/Header/Header";

// Hooks
import { Outlet } from "react-router-dom";

export default function SecondLayout() {
  return (
    <div className={styles.container}>
      <LNBPanel />
      <Header />
      <div className={styles.pageBody}>
        <Outlet />
      </div>
    </div>
  );
}
