import styles from "./RefreshButton.module.scss";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refreshIcon.svg";

export default function RefreshButton({ size = "24px", onClick = undefined }) {
  return (
    <button className={styles.RefreshButton} onClick={onClick}>
      <RefreshIcon style={{ width: size, height: size }} />
    </button>
  );
}
