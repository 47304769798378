import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getUser } from 'src/utils/getUser';

export const userContext = createContext();
const RouterGuard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const value = useMemo(() => user, [user]);

    useEffect(() => {
        if (localStorage.getItem('OpenFrameAccessToken')) {
            getUser().then((res) => {
                setUser(res);
            });
        } else {
            navigate('/signin');
        }
    }, [location]);
    return (
        <userContext.Provider value={value}>
            <Outlet />
        </userContext.Provider>
    );
};

export default RouterGuard;
