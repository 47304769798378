import s from './Deployment.module.scss';

import { useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect, useCallback } from 'react';
import { userContext } from 'src/router/RouterGuard';

import NoDeploymentIllust from 'src/assets/illust/noDataBear.svg';
import { ReactComponent as AddIcon } from 'src/assets/icons/add.svg';

import { convertIcon, convertStatus } from 'src/utils/convert';
import axiosUtil from 'src/utils/axiosUtil';

import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import DeploymentCard from './DeploymentCard';
import DeploymentDetail from './DeploymentDetail';
import Loading from 'src/components/organisms/Loading/Loading';
import OverviewDeploymentEmpty from 'src/components/organisms/OverviewDeploymentEmpty/OverviewDeploymentEmpty';
import RequestCard from './RequestCard/RequestCard';
import RequestDetail from './RequestDetail/RequestDetail';
import Splitter from 'src/components/atoms/Splitter/Splitter';
import FilterChip from 'src/components/atoms/FilterChip/FilterChip';
import NotificationToast from 'src/components/atoms/NotificationToast/NotificationToast';

const Deployment = () => {
    const user = useContext(userContext);
    const navigate = useNavigate();

    // Toast
    const [toastMessage] = useState(localStorage.getItem('ToastMessage'));
    const [isToastOpen, setIsToastOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [deploymentList, setDeploymentList] = useState();
    const [requestList, setRequestList] = useState();
    // const [isFreeTrialAvailable, setIsFreeTrialAvailable] = useState(true);
    const [selectedDeployment, setSelectedDeployment] = useState({
        isDeployment: false, // 선택한게 deployment면 true, request면 false
        deployment: '',
    });
    const [filterList, setFilterList] = useState([
        {
            title: 'Requested',
            type: 'REQUESTED',
            selected: true,
        },
        {
            title: 'Create In Progress',
            type: 'CREATE_IN_PROGRESS',
            selected: true,
        },
        {
            title: 'Active',
            type: 'ACTIVE',
            selected: true,
        },
        {
            title: 'Error',
            type: 'ERROR',
            selected: false,
        },
        {
            title: 'Deleted',
            type: 'DELETED',
            selected: false,
        },
    ]);

    const getDeploymentList = async () => {
        const order = {
            CREATE_COMPLETE: 0,
            '': 1,
            CREATE_IN_PROGRESS: 1,
            CREATE_FAILED: 2,
            DELETE_IN_PROGRESS: 3,
            DELETE_COMPLETE: 4,
        };

        const userData = await axiosUtil
            .get(`/deployments/list?user_id=${user.user_name}`)
            .then((data) => {
                // setIsFreeTrialAvailable(
                //     data?.data?.deployments?.filter((v) => v.free === true)
                //         .length === 0 &&
                //         data?.data?.requests?.filter((v) => v.free === true)
                //             .length === 0
                // );

                data?.data?.deployments?.sort((a, b) => {
                    if (a.deleted !== b.deleted) {
                        return a.deleted ? 1 : -1;
                    }
                    return order[a.stack_status] - order[b.stack_status];
                });

                return data.data;
            })
            .catch((e) => {
                console.log(e);
                return;
            });

        setRequestList(userData.requests ?? []);
        setDeploymentList(userData.deployments ?? []);
        calcDeploymentCnt(userData);
    };

    const calcDeploymentCnt = (userData) => {
        const initCountArr = filterList.reduce((arr, status) => {
            arr.push({ title: status.title, count: 0 });
            return arr;
        }, []);

        // Counting Requests
        if (userData.requests) {
            const status = initCountArr.find(
                (data) => data.title === 'Requested'
            );
            status.count = userData.requests.length;
        }

        // Counting Deployments
        if (userData.deployments) {
            for (const list of userData.deployments) {
                if (list.deleted) {
                    const status = initCountArr.find(
                        (data) => data.title === 'Deleted'
                    );
                    status.count += 1;
                } else {
                    const status = initCountArr.find(
                        (data) =>
                            data.title === convertStatus(list.stack_status)
                    );
                    status.count += 1;
                }
            }
        }

        setFilterList((prev) =>
            prev.map((status, index) => {
                return { ...status, count: initCountArr[index].count };
            })
        );
    };

    const onClickItem = (target) => {
        setFilterList((prev) =>
            prev.map((status, index) => {
                if (target === index) {
                    return { ...status, selected: !status.selected };
                }
                return status;
            })
        );
    };

    const getFilteredDeploymentList = useCallback(() => {
        const selectedStatus = filterList
            .filter((status) => status.selected)
            .map((status) => status.title);

        return deploymentList.filter((v) => {
            if (v.deleted) {
                if (selectedStatus.includes('Deleted')) {
                    return v;
                }
            } else {
                if (selectedStatus.includes(convertStatus(v.stack_status))) {
                    return v;
                }
            }
        });
    }, [filterList, deploymentList]);

    const setFirstDeployment = () => {
        if (filterList[0].selected && requestList && requestList.length !== 0) {
            setSelectedDeployment({
                isDeployment: false,
                deployment: requestList[0],
            });
        } else if (deploymentList && deploymentList.length !== 0) {
            setSelectedDeployment({
                isDeployment: true,
                deployment: getFilteredDeploymentList()[0]?.tenant_name,
            });
        }
    };

    useEffect(() => {
        if (toastMessage) {
            setIsToastOpen(true);
            localStorage.removeItem('ToastMessage');
        }
    }, []);

    useEffect(() => {
        if (user !== null) {
            getDeploymentList();
        }
    }, [user, loading]);

    useEffect(() => {
        setFirstDeployment();
    }, [filterList]);

    if (deploymentList?.length === 0 && requestList?.length === 0)
        return <OverviewDeploymentEmpty user={user} />;
    return (
        <div
            className={`${s.Deployment} 
                        ${!deploymentList && s.loading}`}
        >
            {!deploymentList || !requestList || loading ? (
                <Loading />
            ) : (
                <>
                    <div className={s.header}>
                        <div className={s.chip_container}>
                            {filterList.map((status, index) => (
                                <FilterChip
                                    key={status.type}
                                    index={index}
                                    icon={convertIcon(status.type)}
                                    text={status.title}
                                    count={status.count}
                                    selected={status.selected}
                                    onClick={onClickItem}
                                />
                            ))}
                        </div>
                        <div className={s.button_container}>
                            {/* {isFreeTrialAvailable && (
                                <BasicButton
                                    level='secondary'
                                    type='outline'
                                    content='Try for Free'
                                    onClick={() => navigate('/deployment/free')}
                                />
                            )} */}
                            <BasicButton
                                content='Create Deployment'
                                startIcon={<AddIcon />}
                                onClick={() => navigate('/deployment/create')}
                            />
                        </div>
                    </div>

                    {!selectedDeployment.deployment ? (
                        <div className={s.no_deployment}>
                            <img src={NoDeploymentIllust} alt='No Deployment' />
                            <span className={s.description}>
                                There're no deployment.
                            </span>
                        </div>
                    ) : (
                        <Splitter
                            sizes={[18, 82]} // %
                            minSize={[176, 364]} // px
                            expandToMin={true}
                            direction='vertical'
                            gutterSize={4}
                        >
                            <div className={s.deployment_list}>
                                <div className={s.cards}>
                                    {filterList[0].selected &&
                                        requestList.map((v) => (
                                            <RequestCard
                                                key={v.stack_name}
                                                content={v}
                                                selected={
                                                    selectedDeployment
                                                        .deployment?.stack_name
                                                }
                                                onClick={() => {
                                                    setSelectedDeployment({
                                                        isDeployment: false,
                                                        deployment: v,
                                                    });
                                                }}
                                            />
                                        ))}
                                    {getFilteredDeploymentList().map((v) => (
                                        <DeploymentCard
                                            key={v.tenant_name}
                                            content={v}
                                            selected={
                                                selectedDeployment.deployment
                                            }
                                            onClick={() => {
                                                setSelectedDeployment({
                                                    isDeployment: true,
                                                    deployment: v.tenant_name,
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className={s.deployment_detail}>
                                {!selectedDeployment.isDeployment ? (
                                    <RequestDetail
                                        requestInfo={
                                            selectedDeployment.deployment
                                        }
                                        setLoading={setLoading}
                                    />
                                ) : (
                                    <DeploymentDetail
                                        deployment={
                                            selectedDeployment.deployment
                                        }
                                        setLoading={setLoading}
                                    />
                                )}
                            </div>
                        </Splitter>
                    )}
                </>
            )}
            {isToastOpen && (
                <NotificationToast
                    content={toastMessage}
                    onClickClose={() => setIsToastOpen(false)}
                    styleProps={{ width: '527px' }}
                />
            )}
        </div>
    );
};

export default Deployment;
