// CSS
import './SignUp.css';

// Hooks
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import SignUpAccountInfoPage from './SignUpAccountInfoPage';
import SignUpResendConfirmationPage from './SignUpResendConfirmationPage';
import SignUpWelcomePage from './SignUpWelcomePage';

const SignUp = () => {
    const { email: _email } = useParams();

    const [email, setEmail] = useState(_email);
    const [isAccountInfoCompleted, setIsAccountInfoCompleted] = useState(false);
    const [isConfirmationCompleted, setIsConfirmationCompleted] =
        useState(false);

    useEffect(() => {
        if (email) {
            setIsAccountInfoCompleted(true);
        }
    }, [email]);

    return (
        <>
            {!isAccountInfoCompleted ? (
                <SignUpAccountInfoPage setEmail={setEmail} />
            ) : !isConfirmationCompleted ? (
                <SignUpResendConfirmationPage
                    email={email}
                    setIsConfirmationCompleted={setIsConfirmationCompleted}
                />
            ) : (
                <SignUpWelcomePage />
            )}
        </>
    );
};

export default SignUp;
