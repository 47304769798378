// CSS
import styles from './Support.module.scss';

// Assets
import requestSearchingIllust from '../../assets/illust/requestSearchingIllust.png';

// Hooks
import { useEffect, useState, useContext } from 'react';
import { userContext } from 'src/router/RouterGuard';

// Libraries
import axiosUtil from 'src/utils/axiosUtil';

// Component
import Label from '../../components/atoms/Label/Label';
import BaseSelect from '../../components/atoms/BaseSelect/BaseSelect';
import BasicButton from '../../components/atoms/BasicButton/BasicButton';
import TextField from 'src/components/atoms/TextField/TextField';

export default function Support() {
    const user = useContext(userContext);

    /* state for form */
    const [requestType] = useState('Technical Support');
    const [subject, setSubject] = useState('');
    const [deployment, setDeployment] = useState('none');
    const [service, setService] = useState('');
    const [version] = useState('5.2.0');
    const [details, setDetails] = useState('');

    const [deployments, setDeployments] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isMailSent, setIsMailSent] = useState(false);

    const [serviceArray] = useState([
        { name: 'OF Analyzer', selected: true },
        { name: 'OF Dataset Manager', selected: false },
        { name: 'OF Migrator', selected: false },
    ]);

    useEffect(() => {
        const formArray = [subject, deployment, service, version, details];
        const isAllFieldValid = formArray.every((el) => el !== '');
        setIsFormValid(isAllFieldValid);
    }, [subject, deployment, service, version, details]);

    /* function for deployments */
    useEffect(() => {
        if (user !== null) {
            getDeployments(user);
        }
    }, [user]);

    const getDeployments = async (user) => {
        await axiosUtil
            .get(`/deployments/list?user_id=${user.user_name}`)
            .then((res) => {
                const items = res.data.deployments;

                if (items === null) {
                    setDeployment('none');
                } else if (items.length > 0) {
                    const processedArray = items.map((item, idx) => {
                        return {
                            name: item.tenant_name,
                            selected: idx === 0 ? true : false,
                        };
                    });
                    setDeployments(processedArray);
                    setDeployment(items[0]?.tenant_name);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    /* function for mailing  */
    const onClickSend = async () => {
        setEmptyFields([]);
        if (isFormValid) {
            sendMail();
        } else {
            const formArray = [subject, deployment, service, version, details];
            formArray.map(
                (item) =>
                    item === '' && setEmptyFields((prev) => [...prev, item])
            );
            const isAllFieldValid = formArray.every((el) => el !== '');
            setIsFormValid(isAllFieldValid);
        }
    };

    const sendMail = async () => {
        const requestForm = {
            request_type: requestType,
            subject: subject,
            deployment_name: deployment,
            service_name: service,
            version: version,
            details: details,
        };

        await axiosUtil
            .post(`/mail/authorized/support`, requestForm)
            .then((res) => {
                const status = res.status;
                if (status === 201) {
                    setIsMailSent(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const onClickNewRequest = () => {
        initializeForm();
        setIsMailSent(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const initializeForm = () => {
        setSubject('');
        setDeployment('none');
        setService('');
        setDetails('');
    };

    return (
        <div className={`${styles.Support} ${isMailSent && styles.complete}`}>
            {!isMailSent && (
                <div className={styles.support_container}>
                    <div className={styles.card}>
                        <div className={styles.description}>
                            Please fill out this form and an IT service team
                            expertise will be in touch with you shortly.
                        </div>

                        <div className={styles.form_container}>
                            {/* Request Form */}
                            <div className={styles.content}>
                                {/* Request Type */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Request Type'}
                                            isRequired={true}
                                        />
                                    </div>

                                    <TextField
                                        mode={'edit'}
                                        disabled={true}
                                        labelName={'Request Type'}
                                        value={'Technical Support'}
                                        styleProps={{ width: '245px' }}
                                    />
                                </div>

                                {/* Deployment */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Deployment Name'}
                                            isRequired={true}
                                        />
                                    </div>

                                    {deployments.length > 0 ? (
                                        <BaseSelect
                                            data={deployments}
                                            value={deployment}
                                            onChange={(name) =>
                                                setDeployment(name)
                                            }
                                        />
                                    ) : (
                                        <TextField
                                            mode={'edit'}
                                            disabled={true}
                                            labelName={'Deployment Name'}
                                            value={'No deployment'}
                                            styleProps={{ width: '245px' }}
                                        />
                                    )}
                                </div>

                                {/* Service Name */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Service'}
                                            isRequired={true}
                                        />
                                    </div>
                                    <BaseSelect
                                        data={serviceArray}
                                        value={service}
                                        onChange={(name) => setService(name)}
                                    />
                                </div>

                                {/* Version */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Version'}
                                            isRequired={true}
                                        />
                                    </div>

                                    <TextField
                                        mode={'edit'}
                                        disabled={true}
                                        labelName={'Version'}
                                        value={'5.2.0'}
                                        styleProps={{ width: '245px' }}
                                    />
                                </div>

                                {/* Subject */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Subject'}
                                            isRequired={true}
                                        />
                                    </div>

                                    <TextField
                                        mode={'edit'}
                                        labelName={'Subject'}
                                        placeholder='Subject'
                                        errorMessage={
                                            emptyFields.includes(subject) &&
                                            'Please fill out this field.'
                                        }
                                        value={subject}
                                        onChangeHandler={(e) =>
                                            setSubject(e.target.value)
                                        }
                                    />
                                </div>

                                {/* Details */}
                                <div className={styles.horizontal_wrapper}>
                                    <div className={styles.label}>
                                        <Label
                                            name={'Details'}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className={styles.textarea_wrapper}>
                                        <textarea
                                            className={
                                                emptyFields.includes(details) &&
                                                styles.error
                                            }
                                            placeholder='Please describe your request.'
                                            onChange={(e) =>
                                                setDetails(e.target.value)
                                            }
                                        />

                                        {emptyFields.includes(details) && (
                                            <span className={styles.error_text}>
                                                Please fill out this field.
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.commit_bar}>
                        <button
                            className={styles.commit_button}
                            onClick={onClickSend}
                        >
                            Send Request
                        </button>

                        {/* <button
                            className={`${styles.commit_button} ${
                                isFormValid ? undefined : styles.disabled
                            }`}
                            onClick={isFormValid ? onClickSend : undefined}
                        >
                            Send Request
                        </button> */}
                    </div>
                </div>
            )}

            {isMailSent && (
                <div className={styles.complete_card}>
                    {/* Complete Card */}
                    <img src={requestSearchingIllust} alt='illust' />
                    <span className={styles.text}>
                        Thank you for submitting the request.
                        <br />
                        IT service team expertise will be in touch with you
                        shortly.
                    </span>
                    <BasicButton
                        content={'New Support Request'}
                        onClick={onClickNewRequest}
                    />
                </div>
            )}
        </div>
    );
}
