import s from './LNBPanel.module.scss';

// Assets
import { ReactComponent as Logo } from 'src/assets/logo/OFRefactorLogo_color_vertical.svg';
import { ReactComponent as KeyboardArrowRightIcon } from 'src/assets/icons/keyboard_arrow_right.svg';

// Hooks
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { userContext } from 'src/router/RouterGuard';

const LNBMenu = ({ type, depth = 1, ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [isClicked, setIsClicked] = useState();

    const depthStyle = 'depth' + depth;
    const openStyle = open ? 'on' : 'off';
    const paddingLeft = 20 + (depth - 1) * 12;

    const onClickhandler = () => {
        if (type === 'branch') {
            setOpen((prev) => !prev);
        } else {
            navigate(props.location);
        }
    };

    useEffect(() => {
        setIsClicked(location.pathname === props.location);
    }, [location]);

    return (
        <li className={`${s.LNBMenu} ${s[openStyle]}`}>
            <button
                className={`${s[depthStyle]} 
                            ${isClicked ? s.clicked : ''}`}
                onClick={onClickhandler}
                style={{ paddingLeft }}
            >
                <span>{props.title}</span>
                {type === 'branch' && (
                    <KeyboardArrowRightIcon
                        className={`${s.icon} ${s[openStyle]}`}
                    />
                )}
            </button>
            {props.lists && (
                <ul className={s.subMenu}>
                    {props.lists.map((item, index) => (
                        <LNBMenu
                            key={index}
                            depth={depth + 1}
                            type={item.lists ? 'branch' : 'leaf'}
                            {...item}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default function LNBPanel() {
    const navigate = useNavigate();
    const user = useContext(userContext);

    const defaultMenu = [
        // {
        //     title: 'Overview',
        //     location: '/overview',
        // },
        {
            title: 'Deployment',
            location: '/deployment',
        },
        {
            title: 'Account',
            location: '/account/information',
        },
        // {
        //     title: 'Account',
        //     lists: [
        //         {
        //             title: 'Information',
        //             location: '/account/information',
        //         },
        //         {
        //             title: 'Billing',
        //             location: '/account/billing',
        //         },
        //     ],
        // },
        {
            title: 'Support',
            location: '/support',
        },
        // {
        //     title: 'Event',
        //     location: '/event',
        // },
    ];

    function* generateMenu() {
        for (const menu of defaultMenu) {
            yield menu;
        }

        if (user?.group === 'Admin')
            yield {
                title: 'Admin',
                location: '/admin',
            };
    }

    return (
        <div className={s.LNBPanel}>
            <div
                className={s.logo_wrapper}
                onClick={() => navigate('/deployment')}
            >
                <Logo className={s.logo} />
            </div>
            <ul>
                {[...generateMenu()].map((item, index) => (
                    <LNBMenu
                        key={index}
                        type={item.lists ? 'branch' : 'leaf'}
                        {...item}
                    />
                ))}
            </ul>
        </div>
    );
}
