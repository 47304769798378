import styles from './EventSidePanel.module.scss';

import Label from 'src/components/atoms/Label/Label';
import { ReactComponent as CloseIcon } from 'src/assets/icons/closeIcon.svg';

import { useRef } from 'react';

export default function EventSidePanel({ clickedEvent, onClickClose }) {
    const panelRef = useRef();
    const {
        icon,
        event_name,
        event_level,
        event_object,
        object_name,
        service_model,
        time,
    } = clickedEvent;

    const contents = [
        {
            label: 'Object',
            content: event_object,
        },
        {
            label: 'Object Name',
            content: object_name,
        },
        {
            label: 'Service Model',
            content: service_model,
            underbar: true,
        },
        {
            label: 'Event Name',
            content: event_name,
        },
        {
            label: 'Event Level',
            content: event_level,
            underbar: true,
        },
        {
            label: 'Time',
            content: time,
            underbar: true,
        },
    ];

    const onClickCloseButton = () => {
        panelRef.current.classList.add(`${styles.slideout}`);
        setTimeout(() => {
            panelRef.current.classList.remove(`${styles.slideout}`);
            onClickClose();
        }, 500);
    };

    return (
        <div className={styles.EventSidePanel} ref={panelRef}>
            <div className={`${styles.header} ${styles[event_level]}`}>
                <div className={styles.title_wrapper}>
                    {icon}
                    <span>{event_name}</span>
                </div>

                <div className={styles.closeIcon} onClick={onClickCloseButton}>
                    <CloseIcon />
                </div>
            </div>

            <div className={styles.content_container}>
                {contents.map((el) => (
                    <>
                        <div className={styles.item_wrapper}>
                            <div className={styles.label}>
                                <Label name={el.label} />
                            </div>
                            <span className={styles.content}>{el.content}</span>
                        </div>
                        {el.underbar && <hr />}
                    </>
                ))}
            </div>
        </div>
    );
}
