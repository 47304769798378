import './SignIn.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosUtil from 'src/utils/axiosUtil';
import useShake from 'src/hooks/useShake';
import { encryptText } from 'src/utils/encryptUtil';
import { checkEmailFormat } from 'src/utils/checkFormat';

import { ReactComponent as Illust } from 'src/assets/illust/signInIllust.svg';
import { ReactComponent as Logo } from 'src/assets/logo/OFRefactorLogo_color.svg';

import TopLabelInput from 'src/components/atoms/TopLabelInput/TopLabelInput';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';
import Link from 'src/components/atoms/Link/Link';
import NotificationToast from 'src/components/atoms/NotificationToast/NotificationToast';
import { getUser } from 'src/utils/getUser';
import { getDeploymentDestination } from 'src/utils/redirectUtil';

export default function SignIn() {
    const navigate = useNavigate();
    const { shake, shakeAlert } = useShake();

    // Remeneber ID
    const lastAuthUser = localStorage.getItem('LastAuthUser');
    const [isRememberId, setIsRememberId] = useState(
        JSON.parse(localStorage.getItem('rememberId')) ?? false
    );

    // Toast
    const [toastMessage] = useState(localStorage.getItem('ToastMessage'));
    const [isToastOpen, setIsToastOpen] = useState(false);

    // Login
    const [userId, setUserId] = useState(isRememberId ? lastAuthUser : '');
    const [isNextClicked, setIsNextClicked] = useState(false);
    const [password, setPassword] = useState('');
    const [isUserIdValid, setUserIdValid] = useState(false);

    const [isUserInValid, setIsUserInValid] = useState(false);
    const [warnMessage, setWarnMessage] = useState();

    function handleCheckboxChange(event) {
        setIsRememberId(event.target.checked);
    }

    const checkLogin = () => {
        if (localStorage.getItem('OpenFrameAccessToken')) {
            redirectToDeploymentPage();
        }
    };

    const checkToastMessage = () => {
        if (toastMessage) {
            setIsToastOpen(true);
            localStorage.removeItem('ToastMessage');
        }
    };

    const onClickLogin = () => {
        checkUserInfo();
    };

    const handleEnterKeyDown = (e, action) => {
        if (e.key === 'Enter') {
            if (action === 'next') {
                onClickNext();
            } else if (action === 'login') {
                checkUserInfo();
            }
        }
    };

    const onClickNext = () => {
        setPassword('');
        setWarnMessage('');
        setIsUserInValid(false);
        setIsNextClicked((prev) => !prev);
    };

    const rememberUserId = () => {
        localStorage.setItem('LastAuthUser', userId);
        localStorage.setItem('rememberId', isRememberId);
    };

    const redirectToDeploymentPage = () => {
        getUser()
            .then((user) => {
                const destination = getDeploymentDestination(user);
                return destination;
            })
            .then((destination) => {
                navigate(destination);
            });
    };

    const checkUserInfo = async () => {
        const authenticationData = {
            user_name: userId,
            password: await encryptText(password),
        };

        axiosUtil({
            url: `/auth/signin`,
            method: 'POST',
            data: authenticationData,
        })
            .then((result) => {
                const accessToken = result.data.access_token;
                localStorage.setItem('OpenFrameAccessToken', accessToken);

                rememberUserId();
                setIsUserInValid(false);
                redirectToDeploymentPage();
            })
            .catch((err) => {
                const message = err.response.data.message;
                switch (message) {
                    case 'Incorrect username or password.':
                        setWarnMessage(
                            <span>
                                Your ID or password is incorrect. Please try
                                again.
                            </span>
                        );
                        shakeAlert();
                        break;
                    case 'Password attempts exceeded':
                        setWarnMessage(() => (
                            <>
                                <span>Too many attempts. Please </span>
                                <span
                                    className='warn_button'
                                    onClick={() => navigate('/forgot_password')}
                                >
                                    reset your password.
                                </span>
                            </>
                        ));
                        shakeAlert();
                        break;
                    case 'User is not confirmed.':
                        setWarnMessage(() => (
                            <>
                                <span>{message} Please </span>
                                <span
                                    className='warn_button'
                                    onClick={onClickRedirectConfirmCodePage}
                                >
                                    get your email verification
                                </span>
                            </>
                        ));
                        shakeAlert();
                        break;
                    default:
                        setWarnMessage('Login Error.');
                        shakeAlert();
                }
                setIsUserInValid(true);
            });
    };

    const onClickRedirectConfirmCodePage = async () => {
        await axiosUtil({
            url: `/auth/resend/code`,
            method: 'POST',
            data: {
                user_name: userId,
            },
        })
            .then((res) => {})
            .catch((error) => {
                alert('resend error');
            });

        navigate(`/signup/${userId}`);
    };

    useEffect(() => {
        checkLogin();
        checkToastMessage();
    }, []);

    useEffect(() => {
        setUserIdValid(() => checkEmailFormat(userId));
    }, [userId]);

    const idContent = (
        <div className='input_container'>
            <TopLabelInput
                label={'User ID'}
                targetId={'user_id'}
                placeholder={'name@company.com'}
                onChangeHandler={(e) => {
                    setUserId(e.target.value);
                }}
                onKeyDown={(e) => handleEnterKeyDown(e, 'next')}
                value={userId}
                inputStyle={{ width: '336px' }}
            />

            <div className='button_container'>
                <div className='checkbox_container'>
                    <input
                        type='checkbox'
                        onChange={handleCheckboxChange}
                        checked={isRememberId}
                        id='checkbox'
                    />
                    <label htmlFor='checkbox'>Remember ID</label>
                </div>

                <BasicButton
                    content={'Next'}
                    onClick={onClickNext}
                    disabled={!isUserIdValid}
                    styleProps={{
                        width: '336px',
                        height: '44px',
                    }}
                />
                <Link
                    text='Create Account'
                    onClick={() => navigate('/signup')}
                    styleProps={{
                        color: '#7A828E',
                    }}
                />
            </div>
        </div>
    );

    const passwordContent = (
        <>
            <div
                className={`input_container ${isNextClicked ? 'password' : ''}`}
            >
                <div className='input_wrapper'>
                    <TopLabelInput
                        label={'Password'}
                        inputType='password'
                        targetId={'password'}
                        onChangeHandler={(e) => {
                            setPassword(e.target.value);
                        }}
                        onKeyDown={(e) => handleEnterKeyDown(e, 'login')}
                        value={password}
                        inputStyle={{
                            width: '336px',
                            outline: isUserInValid && '1px solid #ef4433',
                            border: isUserInValid && '1px solid transparent',
                        }}
                        autoFocus
                    />

                    {warnMessage && (
                        <div className={`warning_text ${shake ? 'shake' : ''}`}>
                            <span>{warnMessage}</span>
                        </div>
                    )}
                </div>

                <div className='button_container'>
                    <BasicButton
                        content={'Login'}
                        onClick={onClickLogin}
                        styleProps={{
                            width: '336px',
                            height: '44px',
                        }}
                    />
                    <Link
                        text='Forgot Password?'
                        onClick={() => navigate('/forgot_password')}
                        styleProps={{
                            color: '#7A828E',
                            alignSelf: 'flex-end',
                        }}
                    />
                </div>
            </div>
        </>
    );

    return (
        <div className='TestSignIn'>
            <Illust />
            <div className={`input_box ${isNextClicked ? 'password' : ''}`}>
                <div className='logo_wrapper'>
                    <Logo />
                    {isNextClicked && (
                        <div
                            className='id_chip'
                            onClick={() => setIsNextClicked(false)}
                        >
                            {userId}
                        </div>
                    )}
                </div>

                {isNextClicked ? passwordContent : idContent}
            </div>
            {isToastOpen && (
                <NotificationToast
                    content={toastMessage}
                    onClickClose={() => setIsToastOpen(false)}
                    styleProps={{ width: '527px' }}
                />
            )}
        </div>
    );
}
