import s from './FreeNotification.module.scss';

import { ReactComponent as InfoFilledIcon } from 'src/assets/icons/info_filled.svg';
import { convertTimestamp } from 'src/utils/convert';

const FreeNotification = ({ createdTimestamp }) => {
    const FREE_DAYS = 90;
    const FREE_TO_PAID_NOTI_DAYS = 7;

    const today = new Date();
    const createdDate = new Date(createdTimestamp);

    const freeEndDate = new Date(createdDate);
    freeEndDate.setDate(createdDate.getDate() + FREE_DAYS);

    const freeToPaidNotiEndDate = new Date(freeEndDate);
    freeToPaidNotiEndDate.setDate(
        freeEndDate.getDate() + FREE_TO_PAID_NOTI_DAYS
    );

    const getNotification = () => {
        if (today < freeEndDate) {
            return (
                <>
                    Your free trial ends on
                    <strong>
                        {` ${convertTimestamp(freeEndDate).slice(0, -3)} `}
                    </strong>
                    <span className={s.sub_description}>
                        (After expiration, it will automatically transition to a
                        paid service.)
                    </span>
                </>
            );
        } else if (freeEndDate < today < freeToPaidNotiEndDate) {
            return <>It has been transitioned to a paid service.</>;
        }
    };

    return (
        today < freeToPaidNotiEndDate && (
            <div className={s.FreeNotification}>
                <InfoFilledIcon className={s.icon} />
                <p className={s.description}>{getNotification()}</p>
            </div>
        )
    );
};

export default FreeNotification;
