import styles from './Admin.module.scss';

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from 'src/router/RouterGuard';
import { AgGridReact } from 'ag-grid-react';
import NoRowsOverlay from 'src/components/atoms/NoRowsOverlay/NoRowsOverlay';
import { convertTimestamp } from 'src/utils/convert';
import axiosUtil from 'src/utils/axiosUtil';

import Loading from 'src/components/organisms/Loading/Loading';
import BasicButton from 'src/components/atoms/BasicButton/BasicButton';

export default function Admin() {
    const navigate = useNavigate();
    const user = useContext(userContext);
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState();
    const [rowData, setRowData] = useState([]);
    const default_cloud_provider = 'aws';

    const getRequests = async () => {
        const data = await axiosUtil
            .get(`/admin/requests/list`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                alert('GET /request failed', error);
            });

        setRequests(data ?? []);
    };

    useEffect(() => {
        if (requests && requests.length > 0) {
            const updatedRequest = requests.map((request) => ({
                ...request,
                cloud_provider: request.cloud_provider || default_cloud_provider
            }));
            updatedRequest.sort((a, b) => b.requested_timestamp - a.requested_timestamp);
            setRowData(updatedRequest);
        }
    }, [requests]);

    useEffect(() => {
        if (user && user?.group !== 'Admin') {
            navigate('/');
        }
        getRequests();
    }, [user]);

    const createFreeDeployment = async (data) => {
        const requestUrl = `/admin/deployments/free`;

        const requestBody = {
            // tenant_name: `${user?.company_name}-${data.deployment_name}`,
            tenant_name: data.stack_name,
            deployment_name: data.deployment_name,
            email: data.user_id,
            region: data.region,
            option: 'OF Analyzer',
            tier: '1',
            cloud_provider: data.cloud_provider || default_cloud_provider
        };

        try {
            await axiosUtil.post(requestUrl, requestBody);
            await changeApproveStatus(data);
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const createDeployment = async (data) => {
        const requestUrl = `/admin/deployments`;
        const ddbRequestUrl = `/admin/deployments/item`;

        const requestBody = {
            // tenant_name: `${user?.company_name}-${data.deployment_name}`,
            tenant_name: data.stack_name,
            deployment_name: data.deployment_name,
            email: data.user_id,
            region: data.region,
            option: data.option,
            tier: data.tier,
            cloud_provider: data.cloud_provider || default_cloud_provider
        };

        const ddbRequestBody = {
            // tenant_name: `${user?.company_name}-${data.deployment_name}`,
            tenant_name: data.stack_name,
            deployment_name: data.deployment_name,
            email: data.user_id,
            region: data.region,
            option: data.option,
            tier: data.tier,
            cloud_provider: data.cloud_provider || default_cloud_provider
        };

        try {
            setLoading(true);
            const ddbResponse = await axiosUtil.post(
                ddbRequestUrl,
                ddbRequestBody
            );
            if (ddbResponse.status === 201) {
                await axiosUtil.post(requestUrl, requestBody);
                await changeApproveStatus(data);

                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            if (error.response.data.message.includes('already exist')) {
                alert(error.response.data.message);
            }
            setLoading(false);
        }
    };

    const deleteDeployment = async (data) => {
        setLoading(true);
        await axiosUtil
            .delete(`/deployments`, {
                data: {
                    // tenant_name: `${user?.company_name}-${data.deployment_name}`,
                    tenant_name: data.stack_name,
                    region: data.region,
                    bucket_name: `tmaxsoft-miner-ui-${data.deployment_name}`,
                },
            })
            .then(async (data) => {
                await changeApproveStatus(data);
            })
            .catch(() => {
                return null;
            })
            .finally(async () => {
                setLoading(false);
            });
    };

    const changeApproveStatus = async (data) => {
        await axiosUtil
            .post(`/admin/requests/deployments/approval`, {
                user_id: data.user_id,
                requested_timestamp: data.requested_timestamp.toString(),
            })
            .then(() => {
                console.log(`${data.user_id}'s request approved`);
            })
            .catch((err) => {
                console.log('PUT Failed', err);
            });
    };

    const returnApproveButton = (data) => {
        const { approved, request_type, free } = { ...data };

        if (approved) {
            return (
                <BasicButton
                    content='Approved'
                    level='secondary'
                    type='outline'
                    disabled
                    styleProps={{ width: '84px' }}
                />
            );
        }

        const clickAction = async () => {
            if (request_type === 'DELETE') {
                await deleteDeployment(data);
            } else if (request_type === 'CREATE') {
                if (free) {
                    await createFreeDeployment(data);
                } else {
                    await createDeployment(data);
                }
            }

            getRequests();
        };

        return (
            <BasicButton
                content='Approve'
                onClick={clickAction}
                styleProps={{ width: '84px' }}
            />
        );
    };

    const [colDefs, setColDefs] = useState([
        {
            field: 'request_type',
            headerName: 'Request Type',
            cellStyle: (params) => {
                if (params.value === 'CREATE') {
                    return { color: '#2CAD7E' };
                } else if (params.value === 'DELETE') {
                    return { color: '#ef4433' };
                }
            },
        },
        {
            field: 'user_id',
            headerName: 'User',
        },
        {
            field: 'deployment_name',
            headerName: 'Deployment',
        },
        {
            field: 'free',
            headerName: 'Free',
            cellRenderer: (param) => {
                return param.value ? 'Yes' : 'No';
            },
        },
        {
            field: 'cloud_provider',
            headerName: 'Cloud Provider',
        },
        {
            field: 'region',
            headerName: 'Region',
        },
        {
            field: 'tier',
            headerName: 'Tier',
        },
        {
            field: 'option',
            headerName: 'Option',
        },
        {
            field: 'requested_timestamp',
            headerName: 'Time',
            cellRenderer: (param) => {
                return convertTimestamp(param.value);
            },
        },
        {
            field: 'request_type',
            headerName: 'Approval',
            cellRenderer: (param) => {
                return returnApproveButton(param.data);
            },
            comparator: (value1, value2, node1, node2) => {
                return node1.data.approved - node2.data.approved;
            },
        },
    ]);

    const autoSizeStrategy = {
        type: 'fitGridWidth',
    };

    if (loading)
        return (
            <div className='loading_screen'>
                <Loading />
            </div>
        );

    return (
        <div className={styles.Admin}>
            {!requests ? (
                <Loading />
            ) : (
                <div className={styles.content_container}>
                    <div className={styles.card}>
                        <div className={styles.grid_container}>
                            <div
                                className='ag-theme-dui'
                                style={{ height: 500 }}
                            >
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                    noRowsOverlayComponent={
                                        noRowsOverlayComponent
                                    }
                                    rowStyle={{ cursor: 'pointer' }}
                                    autoSizeStrategy={autoSizeStrategy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const noRowsOverlayComponent = () => {
    return <NoRowsOverlay text='No Request To Show' />;
};
