import s from './AccountChangePassword.module.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosUtil from 'src/utils/axiosUtil';
import { encryptObject } from 'src/utils/encryptUtil';
import { clearLocalStorage } from 'src/utils/clearLocalStorage';

import Label from 'src/components/atoms/Label/Label';
import TextField from 'src/components/atoms/TextField/TextField';
import SimpleModal from 'src/components/molecules/SimpleModal/SimpleModal';

function AccountChangePassword({ setIsChangePasswordModalOpen }) {
    const navigate = useNavigate();

    // Password
    const [originalPassword, setOriginalPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    // Password validation
    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasUpperLowerCase, setHasUpperLowerCase] = useState(false);
    const [hasNumbers, setHasNumbers] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasNotSamePassword, setHasNotSamePassword] = useState(false); // original & new
    const [hasSamePassword, setHasSamePassword] = useState(false); // new & confirm

    // Error Message
    const [originalPasswordError, setOriginalPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

    const checkPasswordRule = (password) => {
        const minumumLength = 12;
        setHasMinimumLength(minumumLength <= password.length);
        setHasUpperLowerCase(/[A-Z]/.test(password) && /[a-z]/.test(password));
        setHasNumbers(/\d/.test(password));
        setHasSpecialCharacters(
            /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=|\\'\\"]/g.test(password)
        );
    };

    const onOriginalPasswordFieldChange = (e) => {
        setOriginalPassword(e.target.value);
        setOriginalPasswordError('');

        const hasNotSame = e.target.value !== newPassword;
        setHasNotSamePassword(hasNotSame);
        changeNewPasswordMessage(hasNotSame);
    };

    const onNewPasswordFieldChange = (e) => {
        setNewPassword(e.target.value);
        checkPasswordRule(e.target.value);

        const hasNotSame = e.target.value !== originalPassword;
        setHasNotSamePassword(hasNotSame);
        changeNewPasswordMessage(hasNotSame);

        const hasSame = e.target.value === confirmNewPassword;
        setHasSamePassword(hasSame);
        changeConfirmNewPasswordMessage(hasSame);
    };

    const onConfirmNewPasswordFieldChange = (e) => {
        setConfirmNewPassword(e.target.value);

        const hasSame = e.target.value === newPassword;
        setHasSamePassword(hasSame);
        changeConfirmNewPasswordMessage(hasSame);
    };

    const changeNewPasswordMessage = (hasNotSame) => {
        if (originalPassword && newPassword)
            if (hasNotSame) {
                setNewPasswordError('');
            } else {
                setNewPasswordError(
                    'The password does not satisfy the password policies. Previous passwords cannot be reused. Please choose a different password.'
                );
            }
    };

    const changeConfirmNewPasswordMessage = (hasSame) => {
        if (confirmNewPassword) {
            if (hasSame) {
                setConfirmNewPasswordError('');
            } else {
                setConfirmNewPasswordError(
                    'The password confirmation does not match.'
                );
            }
        }
    };

    const handleLogOut = () => {
        clearLocalStorage();
        localStorage.setItem(
            'ToastMessage',
            'Your password has been changed successfully'
        );
        navigate('/signin');
    };

    const changePassword = async () => {
        const changePasswordData = await encryptObject({
            previous_password: originalPassword,
            password: newPassword,
        });

        axiosUtil({
            url: `/users/password`,
            method: 'PATCH',
            data: changePasswordData,
        })
            .then(() => {
                handleLogOut();
            })
            .catch((err) => {
                setOriginalPasswordError(err.response.data.message);
            });
    };

    const passwordHelper = (
        <div className={s.help}>
            <p className={`${hasMinimumLength && s.complete}`}>
                Must be at least 12 characters long
            </p>
            <p className={`${hasUpperLowerCase && s.complete}`}>
                Must contain an uppercase and a lowercase letter (A,z)
            </p>
            <p className={`${hasNumbers && s.complete}`}>
                Must contain a number
            </p>
            <p className={`${hasSpecialCharacters && s.complete}`}>
                Must contain a special character (!, %, @, #, etc.)
            </p>
        </div>
    );

    const changePasswordModalContent = (
        <div className={s.changePasswordModalContent}>
            <div className={s.content}>
                <div className={s.vertical_wrapper}>
                    <Label name={'Original Password'} />
                    <TextField
                        type='password'
                        labelName={'Original Password'}
                        value={originalPassword}
                        placeholder='Please enter password'
                        onChangeHandler={onOriginalPasswordFieldChange}
                        errorMessage={originalPasswordError}
                    />
                </div>

                <div className={s.vertical_wrapper}>
                    <Label name={'New Password'} />
                    <TextField
                        type='password'
                        labelName={'New Password'}
                        value={newPassword}
                        placeholder='Please enter new password'
                        onChangeHandler={onNewPasswordFieldChange}
                        helpMessage={passwordHelper}
                        errorMessage={newPasswordError}
                    />
                </div>

                <div className={s.vertical_wrapper}>
                    <Label name={'Confirm New Password'} />
                    <TextField
                        type='password'
                        labelName={'Confirm New Password'}
                        value={confirmNewPassword}
                        placeholder='Please confirm your password'
                        onChangeHandler={onConfirmNewPasswordFieldChange}
                        errorMessage={confirmNewPasswordError}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <SimpleModal
            title='Change Password'
            children={changePasswordModalContent}
            onClickClose={() => setIsChangePasswordModalOpen(false)}
            onClickOK={changePassword}
            okButtonText='확인'
            okButtonDisabled={
                !(
                    hasMinimumLength &&
                    hasUpperLowerCase &&
                    hasNumbers &&
                    hasSpecialCharacters &&
                    hasNotSamePassword &&
                    hasSamePassword &&
                    originalPassword &&
                    newPassword &&
                    confirmNewPassword
                )
            }
        />
    );
}

export default AccountChangePassword;
