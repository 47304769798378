import axios from 'axios';
import { refreshToken } from 'src/hooks/refreshToken';

const axiosUtil = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

// Request 인터셉터
// - 모든 axios 요청에 Authorization 추가
axiosUtil.interceptors.request.use(
    function (config) {
        const accessToken = localStorage.getItem('OpenFrameAccessToken');
        config.headers.Authorization = accessToken;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Response 인터셉터
axiosUtil.interceptors.response.use(
    function (res) {
        return res;
    },
    async function (error) {
        console.log('error', error);
        const { config, response } = error;

        // 재요청 방어 로직
        if (config.sent) {
            if (response.status === 401) {
                return;
            }
            return Promise.reject(error);
        }

        // 토큰 만료시
        config.sent = true; // 재귀 loop 방지
        if (response.status === 401) {
            if (await refreshToken()) {
                const newAccessToken = localStorage.getItem(
                    'OpenFrameAccessToken'
                );
                config.headers.Authorization = newAccessToken;
            }
            return axiosUtil(config);
        }
        return Promise.reject(error);
    }
);

export default axiosUtil;
