// CSS
import './CopyrightLogo.css';

// Assets
import { ReactComponent as TmaxsoftLogo } from 'src/assets/logo/TmaxsoftLogo.svg';

const CopyrightLogo = ({ isOneColorBG }) => {
    return (
        <div className={`CopyrightLogo ${isOneColorBG && 'blue'}`}>
            <TmaxsoftLogo />
            <div className='tmaxsoft_logo-text'>
                Copyrightⓒ 2023. TmaxSoft, All Rights Reserved.
                {!isOneColorBG && (
                    <div className='tmaxsoft-announce_field'>
                        <span
                            id='terms-of-use-textfield'
                            className='text_button'
                            onClick={() =>
                                (window.location.href =
                                    'http://portal.tmaxsoft-openframe.com/termsandconditions')
                            }
                        >
                            Terms of Use
                        </span>
                        <span
                            id='privacy-policy-textfield'
                            className='text_button'
                            onClick={() =>
                                (window.location.href =
                                    'http://portal.tmaxsoft-openframe.com/privacypolicy')
                            }
                        >
                            Privacy policy
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CopyrightLogo;
