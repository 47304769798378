import axiosUtil from 'src/utils/axiosUtil';
import { encryptText } from 'src/utils/encryptUtil';

const authenticateUser = async (userName, password) => {
    const authenticationData = {
        user_name: userName,
        password: await encryptText(password),
    };

    return new Promise((resolve) => {
        axiosUtil({
            url: `/auth/signin`,
            method: 'POST',
            data: authenticationData,
        })
            .then(() => {
                resolve({ success: true, message: '' });
            })
            .catch((err) => {
                resolve({ success: false, message: err.response.data.message });
            });
    });
};

export default authenticateUser;
