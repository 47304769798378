// icons for deployment status
import { ReactComponent as RequestedIcon } from 'src/assets/icons/requested-icon.svg';
import { ReactComponent as ActiveIcon } from 'src/assets/icons/active-icon.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/errorIcon.svg';
import { ReactComponent as DeletedIcon } from 'src/assets/icons/deleted-icon.svg';
import { ReactComponent as CreateInProgressIcon } from 'src/assets/icons/creating.svg';

// icons for event
import EventInfoIcon from 'src/assets/icons/eventInfoIcon.svg';
import EventWarningIcon from 'src/assets/icons/eventWarningIcon.svg';
import EventCriticalIcon from 'src/assets/icons/eventCriticalIcon.svg';
import EventSevereIcon from 'src/assets/icons/eventSevereIcon.svg';

export const convertIcon = (status) => {
    switch (status) {
        case 'REQUESTED':
            return <RequestedIcon />;
        case 'Requested':
            return <RequestedIcon />;

        case 'CREATE_COMPLETE':
            return <ActiveIcon />;
        case 'ACTIVE':
            return <ActiveIcon />;
        case 'Active':
            return <ActiveIcon />;

        case 'CREATE_FAILED':
            return <ErrorIcon />;
        case 'Error':
            return <ErrorIcon />;

        case 'DELETE_IN_PROGRESS':
            return <DeletedIcon />;
        case 'DELETE_COMPLETE':
            return <DeletedIcon />;
        case 'DELETED':
            return <DeletedIcon />;
        case 'Deleted':
            return <DeletedIcon />;

        case 'CREATE_IN_PROGRESS':
            return <CreateInProgressIcon />;
        case 'Create In Progress':
            return <CreateInProgressIcon />;

        default:
            return <ErrorIcon />;
    }
};

export const convertStatus = (status) => {
    switch (status) {
        case 'CREATE_IN_PROGRESS':
            return 'Create In Progress';
        case 'CREATE_COMPLETE':
            return 'Active';
        case 'CREATE_FAILED':
            return 'Error';
        case 'DELETE_IN_PROGRESS':
            return 'Deleted';
        case 'DELETE_COMPLETE':
            return 'Deleted';
        default:
            return 'Requested';
    }
};

export const convertModel = (model) => {
    switch (model) {
        case '0':
            return 'OF Analyzer';
        case '1':
            return 'OF Data Migration';
        case '2':
            return 'OF Transformation';
        default:
            return;
    }
};

export const convertMonth = (month) => {
    switch (month) {
        case '01':
            return 'JANUARY';
        case '02':
            return 'FEBRUARY';
        case '03':
            return 'MARCH';
        case '04':
            return 'APRIL';
        case '05':
            return 'MAY';
        case '06':
            return 'JUNE';
        case '07':
            return 'JULY';
        case '08':
            return 'AUGUST';
        case '09':
            return 'SEPTEMBER';
        case '10':
            return 'OCTOBER';
        case '11':
            return 'NOVEMBER';
        case '12':
            return 'DECEMBER';

        default:
            return;
    }
};

export const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp); //타임스탬프를 인자로 받아 Date 객체 생성

    const year = date.getFullYear().toString(); //년도 뒤에 두자리
    const month = ('0' + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    const day = ('0' + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    const yearMonthDate = year + '-' + month + '-' + day;

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const time = hours + ':' + minutes + ':' + seconds;

    return yearMonthDate + ' ' + time;
};

export const convertEvent = (status) => {
    switch (status) {
        case 'severe':
            return {
                icon: EventSevereIcon,
                backgroundColor: '#EF4433',
                color: '#1C293E',
            };
        case 'critical':
            return {
                icon: EventCriticalIcon,
                backgroundColor: '#FF8B0A',
                color: '#1C293E',
            };
        case 'warning':
            return {
                icon: EventWarningIcon,
                backgroundColor: '#FEB72C',
                color: '#1C293E',
            };

        case 'info':
            return {
                icon: EventInfoIcon,
                backgroundColor: '#30BE8B',
                color: '#1C293E',
            };
        default:
            return {
                icon: EventInfoIcon,
                backgroundColor: '#F5F6F7',
                color: '#B9BDC3',
            };
    }
};

export const getUSCurrencyFormat = (value) => {
    // const commaValue = value
    //     .toString()
    //     .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

    const commaValue = parseFloat(value).toLocaleString('en-US', {
        maximumFractionDigits: 2,
    });

    return `$${commaValue}`;
};

export const convertCurrentTimestamp = () => {
    const date = new Date(); //타임스탬프를 인자로 받아 Date 객체 생성

    const year = date.getFullYear().toString(); //년도 뒤에 두자리
    const month = ('0' + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    const day = ('0' + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    const yearMonthDate = year + '-' + month + '-' + day;

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const time = hours + ':' + minutes + ':' + seconds;

    return yearMonthDate + ' ' + time;
};
