import styles from './BaseSelect.module.scss';

// Assets
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrowDownIcon.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrowUpIcon.svg';

// Hooks
import { useEffect, useState } from 'react';
import useClickAway from '../../../hooks/useClickAway';

// Components
import SelectDropdown from '../SelectDropdown/SelectDropdown';

export default function BaseSelect({
    data: defaultValue,
    value,
    onChange,
    inputStyle,
    textStyle,
}) {
    const [data, setData] = useState(defaultValue);

    useEffect(() => {
        setData(defaultValue);
    }, [defaultValue]);

    const {
        isClicked: isSelectClicked,
        clickRef: selectRef,
        onToggle: onSelectToggle,
    } = useClickAway();

    const onClickTrigger = () => {
        onSelectToggle();
    };

    const onClickItem = (target) => {
        return () => {
            if (data[target].selected) return;
            setData((prev) =>
                prev.map(({ name, selected }, idx) => {
                    if (target === idx) {
                        return { name, selected: !selected };
                    }
                    return { name, selected: false };
                })
            );
            onSelectToggle();
        };
    };

    useEffect(() => {
        const selectedName = data.filter(({ selected }) => selected)[0].name;
        onChange(selectedName);
    }, [data]);

    return (
        <div className={styles.selectContainer} ref={selectRef}>
            <button
                onClick={onClickTrigger}
                className={styles.trigger}
                type='button'
                style={inputStyle}
            >
                <p className={styles.text} style={textStyle}>
                    {value}
                </p>
                {isSelectClicked ? (
                    <ArrowUpIcon className={styles.icon} />
                ) : (
                    <ArrowDownIcon className={styles.icon} />
                )}
            </button>
            {isSelectClicked && (
                <SelectDropdown items={data} onClickItem={onClickItem} />
            )}
        </div>
    );
}
