import "./Splitter.css";
import Split from "react-split";

function Splitter({ children, ...props }) {
  const directionStyle = props.direction || "horizontal"; // 기본값 'horizontal'

  return (
    <Split {...props} className={`Splitter ${directionStyle}`}>
      {children}
    </Split>
  );
}

export default Splitter;
