const AsteriskIcon = ({ color = "#7244FB" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <g clipPath="url(#clip0_498_2288)">
      <path
        d="M3.40876 7.66683H4.59156V5.00367L7.07543 6.39315L7.66683 5.40893L5.18296 4.00016L7.66683 2.59139L7.07543 1.60718L4.59156 2.99665V0.333496H3.40876V2.99665L0.924894 1.60718L0.333496 2.59139L2.81737 4.00016L0.333496 5.40893L0.924894 6.39315L3.40876 5.00367V7.66683Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_498_2288">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AsteriskIcon;
