import './App.css';
import CommonRouter from './router/CommonRouter';
import { CrossStorageHub } from 'cross-storage';

function App() {
    const originRegExp = new RegExp(`\\.${process.env.REACT_APP_CLIENT_URL}$`);

    CrossStorageHub.init([
        {
            origin: originRegExp,
            allow: ['get', 'set', 'del', 'getKeys', 'clear'],
        },
    ]);

    return (
        <div className='App'>
            <CommonRouter />
        </div>
    );
}

export default App;
