const clearLocalStorage = () => {
    // 로그인 시 remember id 기능을 위해 LastAuthUser, rememberId 제외하고 삭제
    for (let key in localStorage) {
        if (key.includes('LastAuthUser')) continue;
        if (key.includes('rememberId')) continue;

        localStorage.removeItem(key);
    }
};

const clearAllLocalStorage = () => {
    for (let key in localStorage) {
        localStorage.removeItem(key);
    }
};

export { clearLocalStorage, clearAllLocalStorage };
