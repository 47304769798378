import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function useMoveToTop() {
    const pathName = useLocation();

    useEffect(() => {
        moveToTop();
    }, [pathName]);

    const moveToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return null;
}
